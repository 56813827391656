<template>
  <div class="service-agreement-management">
    <el-form class="zwx-form" ref="serviceAgreementManagementForm" :model="serviceAgreementManagementForm" label-position="right" @submit.native.prevent>
      <div class="self-adapting-condition">
        <el-form-item label="行政区划：" label-width="70px">
          <zwx-select-area class="cascader-area-biz-zone" ref="bizZoneArea" @change="bizZoneChange" :zoneCode12From="serviceAgreementManagementForm.bizZone" zoneTypeMax="0" zoneTypeMin="4" :showFullName="false" :clearable="false" />
        </el-form-item>
        <el-form-item label="单位名称：" label-width="70px">
          <el-input class="zwx-input" v-model.trim="serviceAgreementManagementForm.unitName" style="width: 160px !important" placeholder="" clearable maxlength="50" />
        </el-form-item>
        <el-form-item label="协议有效期：">
          <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 200px !important;" v-model="serviceAgreementManagementForm.contractDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="$validate.noBigDate($data['serviceAgreementManagementForm'], 'contractLimitDate', true)" placeholder="开始日期" />
          -
          <el-date-picker class="zwx-date-picker " popper-class="zwx-date-picker-popper" style="width: 200px !important;" v-model="serviceAgreementManagementForm.contractLimitDate" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" :picker-options="$validate.noSmallDate($data['serviceAgreementManagementForm'], 'contractDate', true)" placeholder="结束日期" />
        </el-form-item>
        <div style="clear: both" />
      </div>
    </el-form>
    <div class="independent-button-row">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="queryserviceAgreementList(1)">查询</el-button>
      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="addServiceAgreement">添加</el-button>
    </div>
    <el-table ref="serviceAgreementTable" class="zwx-table" style="width: 100%;" :data="serviceAgreementManagementFormExp.tableList" border stripe tooltip-effect="light">
      <el-table-column width="260" prop="organName" label="体检机构" header-align="center" align="left" show-overflow-tooltip></el-table-column>
      <el-table-column width="200" label="协议有效期" header-align="center" align="center" show-overflow-tooltip>
        <template slot-scope="scope">{{ scope.row.contractDateStr }} - {{ scope.row.contractLimitDateStr }}</template>
      </el-table-column>
      <el-table-column width="120" label="资质附件" header-align="center" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <template v-if="$zwxBase.verifyIsBlank(scope.row.qualificationList)">
            <div class="zwx-folder-content">
              <i class="filled chis-icon-filled-file-folder" style="color: #1990ff;"></i>
              <span class="zwx-folder-content-num">0</span>
            </div>
          </template>
          <template v-else>
            <el-dropdown placement="bottom" trigger="click">
              <div class="zwx-folder-content">
                <i class="filled chis-icon-filled-file-folder" style="color: #1990ff;"></i>
                <span class="zwx-folder-content-num">{{ scope.row.qualificationList.length }}</span>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in scope.row.qualificationList" :key="index" @click.native="openFilePreview(scope.row.qualificationList, index)">
                  <div>{{ item.fileName }}</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </template>
      </el-table-column>
      <el-table-column width="100" prop="transferDateStr" label="协议附件" header-align="center" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          <template v-if="$zwxBase.verifyIsBlank(scope.row.agreementList)">
            <div class="zwx-folder-content">
              <i class="filled chis-icon-filled-file-folder" style="color: #1990ff;"></i>
              <span class="zwx-folder-content-num">0</span>
            </div>
          </template>
          <template v-else>
            <el-dropdown placement="bottom" trigger="click">
              <div class="zwx-folder-content">
                <i class="filled chis-icon-filled-file-folder" style="color: #1990ff;"></i>
                <span class="zwx-folder-content-num">{{ scope.row.agreementList.length }}</span>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in scope.row.agreementList" :key="index" @click.native="openFilePreview(scope.row.agreementList, index)">
                  <div>{{ item.fileName }}</div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" min-width="130" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" @click="edit(scope.row, scope.$index)" icon="el-icon-top-right">编辑</el-button>
          <el-button class="zwx-button zwx-button-text-26" type="danger" @click="deleteServiceAgreement(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <base-pagination class="normal-pagination" :parentPage="serviceAgreementManagementForm.currentPage" :pageSize="serviceAgreementManagementFormExp.pageSize" :total="serviceAgreementManagementFormExp.total" @currentChange="queryserviceAgreementList" />
    <!--    新增/编辑-->
    <base-dialog ref="serviceAgreementAddDialogRef" class="service-agreement-add-dialog" :pagination="false" :title="serviceAgreementAddDialog.title" @close="closeServiceAgreementAddDialog" @determine="confirmServiceAgreementAddDialog" @cancel="cancelServiceAgreementAddDialog">
      <el-form ref="serviceAgreementAddForm" class="zwx-form edit-form" :model="serviceAgreementAddDialog.serviceAgreementAddForm" :rules="serviceAgreementAddDialog.serviceAgreementAddFormRules">
        <div>
          <div class="edit-row">
            <el-form-item label-width="110px" label="体检机构：" prop="organName">
              <pe-organ-select class="organ-select" ref="peOrganSelectRef" @change="peOrganSelectChange" v-model="serviceAgreementAddDialog.serviceAgreementAddForm.organName" />
            </el-form-item>
          </div>
          <div class="edit-row">
            <el-form-item label-width="110px" label="社会信用代码：" prop="organCreditCode">
              <el-input class="zwx-input" style="width: 450px !important;" v-model.trim="serviceAgreementAddDialog.serviceAgreementAddForm.organCreditCode" placeholder="" clearable disabled></el-input>
            </el-form-item>
          </div>
          <div class="edit-row">
            <el-form-item label-width="110px" label="协议开始时间：" prop="contractDate">
              <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" v-model="serviceAgreementAddDialog.serviceAgreementAddForm.contractDate" :picker-options="$validate.noBigDate($data['serviceAgreementAddDialog']['serviceAgreementAddForm'], 'contractLimitDate', true)" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="" clearable />
            </el-form-item>
            <el-form-item label-width="110px" label="协议结束时间：" prop="contractLimitDate">
              <el-date-picker class="zwx-date-picker" popper-class="zwx-date-picker-popper" v-model="serviceAgreementAddDialog.serviceAgreementAddForm.contractLimitDate" :picker-options="$validate.noSmallDate($data['serviceAgreementAddDialog']['serviceAgreementAddForm'], 'contractDate', true)" format="yyyy-MM-dd" value-format="yyyy-MM-dd" type="date" placeholder="" clearable />
            </el-form-item>
          </div>
          <div class="edit-row">
            <el-form-item label-width="110px" label="资质附件：" prop="certificationFileList">
              <file-upload ref="certificationFileUploadRef" @showFileList="showCertificationFileList" accept=".pdf,.PDF" @success="certificationFileSuccess" @error="fileUploadError" :size="50 * 1024 * 1024" sizeInfo="50M">
                <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2" v-if="$zwxBase.verifyIsBlank(serviceAgreementAddDialog.serviceAgreementAddForm.certificationFileList)">
                  上传
                </el-button>
              </file-upload>
              <div class="show-file" v-if="$zwxBase.verifyIsNotBlank(serviceAgreementAddDialog.serviceAgreementAddForm.certificationFileList)">
                <span @mouseenter="certificationFileMouseenter" @mouseleave="certificationFileMouseleave">
                  <div style="display: flex;align-items:center;height: 32px">
                    <div style="display: flex;align-items:center;color:#194DFF !important;cursor:pointer;" @click="openFilePreview(serviceAgreementAddDialog.serviceAgreementAddForm.certificationFileList, 0)">
                      <i class="colorful chis-icon-colorful-pdf"></i>
                      <div class="name-hidden" style="max-width: 400px;color:#316CF5!important;margin-left:5px;">{{ serviceAgreementAddDialog.serviceAgreementAddForm.certificationFileList[0].fileName }}</div>
                    </div>
                    <div class="el-upload-list__item check" :style="serviceAgreementAddDialog.serviceAgreementAddForm.certificationFileList[0].check ? 'margin-left:5px;display:none;' : 'margin-left:5px;cursor:pointer'">
                      <i class="el-icon-upload-success el-icon-circle-check"></i>
                    </div>
                    <div class="check" :style="serviceAgreementAddDialog.serviceAgreementAddForm.certificationFileList[0].check ? 'margin-left:5px;cursor:pointer' : 'margin-left:5px;display:none;'">
                      <i class="el-icon-close" @click="delCertificationFile(0)"></i>
                    </div>
                  </div>
                </span>
              </div>
            </el-form-item>
          </div>
          <div class="edit-row">
            <el-form-item label-width="110px" label="协议附件：" prop="agreementFileList">
              <file-upload ref="agreementFileUploadRef" @showFileList="showAgreementFileList" accept=".pdf,.PDF" @success="agreementFileSuccess" @error="fileUploadError" :size="50 * 1024 * 1024" sizeInfo="50M">
                <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-upload2" v-if="$zwxBase.verifyIsBlank(serviceAgreementAddDialog.serviceAgreementAddForm.agreementFileList)">
                  上传
                </el-button>
              </file-upload>
              <div class="show-file" v-if="$zwxBase.verifyIsNotBlank(serviceAgreementAddDialog.serviceAgreementAddForm.agreementFileList)">
                <span @mouseenter="agreementFileMouseenter" @mouseleave="agreementFileMouseleave">
                  <div style="display: flex;align-items:center;height: 32px">
                    <div style="display: flex;align-items:center;color:#194DFF !important;cursor:pointer;" @click="openFilePreview(serviceAgreementAddDialog.serviceAgreementAddForm.agreementFileList, 0)">
                      <i class="colorful chis-icon-colorful-pdf"></i>
                      <div class="name-hidden" style="max-width: 400px;color:#316CF5!important;margin-left:5px;">{{ serviceAgreementAddDialog.serviceAgreementAddForm.agreementFileList[0].fileName }}</div>
                    </div>
                    <div class="el-upload-list__item check" :style="serviceAgreementAddDialog.serviceAgreementAddForm.agreementFileList[0].check ? 'margin-left:5px;display:none;' : 'margin-left:5px;cursor:pointer'">
                      <i class="el-icon-upload-success el-icon-circle-check"></i>
                    </div>
                    <div class="check" :style="serviceAgreementAddDialog.serviceAgreementAddForm.agreementFileList[0].check ? 'margin-left:5px;cursor:pointer' : 'margin-left:5px;display:none;'">
                      <i class="el-icon-close" @click="delAgreementFile(0)"></i>
                    </div>
                  </div>
                </span>
              </div>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </base-dialog>
  </div>
</template>

<script>
export default {
  name: 'ServiceAgreementManagement',
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      serviceAgreementManagementForm: {
        employerUuid: '',
        currentPage: 1,
        organName: '',
        contractDate: '',
        contractLimitDate: '',
        bizZone: '',
        unitName: ''
      },
      serviceAgreementManagementFormExp: {
        tableList: [],
        pageSize: 18,
        total: 0,
      },
      serviceAgreementAddDialog: {
        title: '',
        folder: '/health_supervision', //文件存储路径
        serviceAgreementAddForm: {
          rid: '',
          organUid: '',
          organName: '',
          organCreditCode: '',
          contractDate: '',
          contractLimitDate: '',
          certificationFileList: [],
          agreementFileList: [],
        },
        serviceAgreementAddFormRules: {
          organName: [
            { required: true, message: '请选择体检机构', trigger: ['blur'] },
            { validator: this.validatorOrganName, trigger: ['blur'] },
          ],
          organCreditCode: [{ required: true, message: '社会信用代码不能为空', trigger: ['change', 'blur'] }],
          contractDate: [{ required: true, message: '请选择协议开始时间', trigger: ['change', 'blur'] }],
          contractLimitDate: [{ required: true, message: '请选择协议结束时间', trigger: ['change', 'blur'] }],
          certificationFileList: [{ required: false, message: '请上传体检机构资质', trigger: ['change', 'blur'] }],
          agreementFileList: [{ required: false, message: '请上传服务协议', trigger: ['change', 'blur'] }],
        },
      },
    }
  },
  created() {
    if (this.$route.query.employerUuid != undefined) {
      this.serviceAgreementManagementForm.employerUuid = this.$route.query.employerUuid
    }
  },
  activated() {
    if (this.$route.params.fresh) this.queryserviceAgreementList(this.currentPage)
  },
  mounted() {
    // 赋值当前用户所属地区
    this.msForm.bizZone = '440000000000'
    this.$refs.bizZoneArea.initSearch(this.msForm.bizZone)
    this.queryserviceAgreementList(1)
  },
  computed: {},
  methods: {
    bizZoneChange() {
      this.msForm.bizZone = this.$refs.bizZoneArea.getChooseArea().zoneCode12
    },
    /**
     * 查询列表
     */
    queryserviceAgreementList(currentPage) {
      this.serviceAgreementManagementForm.currentPage = currentPage
      let data = {
        ...this.serviceAgreementManagementForm,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/oh/health/surveillance/getPeServiceContractList-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.serviceAgreementManagementFormExp.tableList = data.peServiceContractExpList
            this.serviceAgreementManagementFormExp.pageSize = data.pageSize
            this.serviceAgreementManagementFormExp.total = data.peServiceContractListCount
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$notify.error({ title: '错误', message: data.mess })
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    /**
     * 文件预览
     */
    openFilePreview(fileList, index) {
      top.postMessage(
        {
          handlerType: 'previewFile',
          params: {
            fileList: fileList,
            index: index,
          },
        },
        '*'
      )
    },
    /**
     * 添加
     */
    addServiceAgreement() {
      this.serviceAgreementAddDialog.title = '添加服务协议'
      this.$refs.serviceAgreementAddDialogRef.show(true)
    },
    /**
     * 编辑
     */
    edit(row) {
      this.serviceAgreementAddDialog.title = '修改服务协议'
      let data = {
        rid: row.rid,
      }
      this.$emit('loading', true)
      this.$system.get(
        this.api + '/oh/health/surveillance/getPeServiceContract-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          this.serviceAgreementAddDialog.serviceAgreementAddForm.rid = data.contractExp.rid
          this.serviceAgreementAddDialog.serviceAgreementAddForm.employerUuid = data.contractExp.employerUuid
          this.serviceAgreementAddDialog.serviceAgreementAddForm.organUid = data.contractExp.organUid
          this.serviceAgreementAddDialog.serviceAgreementAddForm.organName = data.contractExp.organName
          this.serviceAgreementAddDialog.serviceAgreementAddForm.organCreditCode = data.contractExp.organCreditCode
          this.serviceAgreementAddDialog.serviceAgreementAddForm.contractDate = data.contractExp.contractDate
          this.serviceAgreementAddDialog.serviceAgreementAddForm.contractLimitDate = data.contractExp.contractLimitDate
          if (this.$zwxBase.verifyIsNotBlank(data.contractExp.qualificationList)) {
            data.contractExp.qualificationList[0].check = false
            this.serviceAgreementAddDialog.serviceAgreementAddForm.certificationFileList = data.contractExp.qualificationList
          }
          if (this.$zwxBase.verifyIsNotBlank(data.contractExp.agreementList)) {
            data.contractExp.agreementList[0].check = false
            this.serviceAgreementAddDialog.serviceAgreementAddForm.agreementFileList = data.contractExp.agreementList
          }
          this.$refs.serviceAgreementAddDialogRef.show(true)
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    /**
     * 删除服务协议
     */
    deleteServiceAgreement(row) {
      this.$system.msgbox('', '提示', '是否确认删除？', '确定', '取消', () => {
        let data = {
          rid: row.rid,
        }
        this.$emit('loading', true)
        this.$system.post(
          this.api + '/oh/health/surveillance/deletePeServiceContract-1',
          data,
          true,
          true,
          data => {
            this.$emit('loading', false)
            if (data.type === '00') {
              this.$system.notify('成功', data.mess, 'success')
              this.queryserviceAgreementList(1)
            } else if (data.type === '99') {
              this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
            } else {
              this.$notify.info({ title: '错误', message: data.mess })
            }
          },
          () => {
            this.$emit('loading', false)
            this.$system.notify('错误', '网络连接失败', 'error')
          }
        )
      })
    },
    validatorOrganName(rule, value, callback) {
      if (this.$zwxBase.verifyIsNotBlank(value) && value.length < 5) {
        return callback(new Error('请至少输入5个字符'))
      }
      return callback()
    },
    /**
     * 体检机构改变回调
     */
    peOrganSelectChange(value) {
      this.serviceAgreementAddDialog.serviceAgreementAddForm.organUid = value.unitUid
      this.serviceAgreementAddDialog.serviceAgreementAddForm.organName = value.unitName
      this.serviceAgreementAddDialog.serviceAgreementAddForm.organCreditCode = value.creditCode
    },
    /**
     * 资质附件添加上传文件回调
     */
    showCertificationFileList(showList) {
      this.serviceAgreementAddDialog.serviceAgreementAddForm.certificationFileList = []
      showList.forEach(item => {
        let flag = this.serviceAgreementAddDialog.serviceAgreementAddForm.certificationFileList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.serviceAgreementAddDialog.serviceAgreementAddForm.certificationFileList.push({
          fileName: item,
          check: false,
        })
      })
      this.$refs.serviceAgreementAddForm.validateField('certificationFileList')
    },
    /**
     * 资质附件鼠标上移回调
     */
    certificationFileMouseenter() {
      this.serviceAgreementAddDialog.serviceAgreementAddForm.certificationFileList[0].check = true
    },
    /**
     * 资质附件鼠标移除回调
     */
    certificationFileMouseleave() {
      this.serviceAgreementAddDialog.serviceAgreementAddForm.certificationFileList[0].check = false
    },
    /**
     * 资质附件上传成功回调
     */
    certificationFileSuccess(filePathList) {
      if (this.$zwxBase.verifyIsNotBlank(this.serviceAgreementAddDialog.serviceAgreementAddForm.certificationFileList) && this.$zwxBase.verifyIsNotBlank(filePathList)) {
        for (let i in this.serviceAgreementAddDialog.serviceAgreementAddForm.certificationFileList) {
          for (let j in filePathList) {
            if (this.serviceAgreementAddDialog.serviceAgreementAddForm.certificationFileList[i].fileName === filePathList[j].fileName) {
              this.serviceAgreementAddDialog.serviceAgreementAddForm.certificationFileList[i].filePath = filePathList[j].filePath
            }
          }
        }
      }
      this.generateFilesUid(2)
    },
    /**
     * 删除资质附件
     */
    delCertificationFile(index) {
      let fileName = this.serviceAgreementAddDialog.serviceAgreementAddForm.certificationFileList[index].fileName
      this.serviceAgreementAddDialog.serviceAgreementAddForm.certificationFileList.splice(index, 1)
      this.$refs.certificationFileUploadRef.deleteIndex(index)
      let fileList = this.$refs.certificationFileUploadRef.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.certificationFileUploadRef.deleteIndex(j)
            fileList = this.$refs.certificationFileUploadRef.showFileList || []
            continue
          }
        }
      }
    },
    /**
     * 服务协议添加上传文件回调
     */
    showAgreementFileList(showList) {
      this.serviceAgreementAddDialog.serviceAgreementAddForm.agreementFileList = []
      showList.forEach(item => {
        let flag = this.serviceAgreementAddDialog.serviceAgreementAddForm.agreementFileList.some(e => e.fileName == item)
        if (flag) {
          return
        }
        this.serviceAgreementAddDialog.serviceAgreementAddForm.agreementFileList.push({
          fileName: item,
          check: false,
        })
      })
      this.$refs.serviceAgreementAddForm.validateField('agreementFileList')
    },
    /**
     * 资质附件鼠标上移回调
     */
    agreementFileMouseenter() {
      this.serviceAgreementAddDialog.serviceAgreementAddForm.agreementFileList[0].check = true
    },
    /**
     * 资质附件鼠标移除回调
     */
    agreementFileMouseleave() {
      this.serviceAgreementAddDialog.serviceAgreementAddForm.agreementFileList[0].check = false
    },
    /**
     * 服务协议上传成功回调
     */
    agreementFileSuccess(filePathList) {
      if (this.$zwxBase.verifyIsNotBlank(this.serviceAgreementAddDialog.serviceAgreementAddForm.agreementFileList) && this.$zwxBase.verifyIsNotBlank(filePathList)) {
        for (let i in this.serviceAgreementAddDialog.serviceAgreementAddForm.agreementFileList) {
          for (let j in filePathList) {
            if (this.serviceAgreementAddDialog.serviceAgreementAddForm.agreementFileList[i].fileName === filePathList[j].fileName) {
              this.serviceAgreementAddDialog.serviceAgreementAddForm.agreementFileList[i].filePath = filePathList[j].filePath
            }
          }
        }
      }
      this.saveServiceAgreement()
    },
    /**
     * 删除协议附件
     */
    delAgreementFile(index) {
      let fileName = this.serviceAgreementAddDialog.serviceAgreementAddForm.agreementFileList[index].fileName
      this.serviceAgreementAddDialog.serviceAgreementAddForm.agreementFileList.splice(index, 1)
      this.$refs.agreementFileUploadRef.deleteIndex(index)
      let fileList = this.$refs.agreementFileUploadRef.showFileList || []
      let count = 0
      fileList.forEach(item => {
        if (fileName === item) {
          count++
        }
      })
      for (let i = 0; i < count; i++) {
        for (let j in fileList) {
          if (fileName === fileList[j]) {
            this.$refs.agreementFileUploadRef.deleteIndex(j)
            fileList = this.$refs.agreementFileUploadRef.showFileList || []
            continue
          }
        }
      }
    },
    /**
     * 文件上传失败回调
     */
    fileUploadError() {
      this.$system.notify('失败', '文件上传失败', 'error')
    },
    /**
     * 获取文件上传uuid
     */
    generateFilesUid(index) {
      let data = {
        employerUuid: this.serviceAgreementManagementForm.employerUuid,
        folder: this.serviceAgreementAddDialog.folder,
      }
      this.$emit('loading', true)
      this.$system.post(
        this.api + '/zky/employer/generateFilesUid-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            if (index === 1) {
              this.$refs.certificationFileUploadRef.submit(data.uid)
            }
            if (index === 2) {
              this.$refs.agreementFileUploadRef.submit(data.uid)
            }
          } else {
            this.$emit('loading', false)
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    /**
     * 确认服务协议添加弹框
     */
    confirmServiceAgreementAddDialog() {
      this.$refs.serviceAgreementAddForm.validate(valid => {
        if (valid) {
          this.generateFilesUid(1)
        }
      })
    },
    /**
     * 取消服务协议添加弹框
     */
    cancelServiceAgreementAddDialog() {
      this.$refs.serviceAgreementAddDialogRef.show(false)
    },
    /**
     * 关闭服务协议添加弹框回调
     */
    closeServiceAgreementAddDialog() {
      this.serviceAgreementAddDialog.title = ''
      this.serviceAgreementAddDialog.serviceAgreementAddForm.rid = ''
      this.serviceAgreementAddDialog.serviceAgreementAddForm.organUid = ''
      this.serviceAgreementAddDialog.serviceAgreementAddForm.organName = ''
      this.serviceAgreementAddDialog.serviceAgreementAddForm.organCreditCode = ''
      this.serviceAgreementAddDialog.serviceAgreementAddForm.contractDate = ''
      this.serviceAgreementAddDialog.serviceAgreementAddForm.contractLimitDate = ''
      this.serviceAgreementAddDialog.serviceAgreementAddForm.certificationFileList = []
      this.serviceAgreementAddDialog.serviceAgreementAddForm.agreementFileList = []
    },
    /**
     * 保存服务协议
     */
    saveServiceAgreement() {
      let data = {
        rid: this.serviceAgreementAddDialog.serviceAgreementAddForm.rid,
        employerUuid: this.serviceAgreementManagementForm.employerUuid,
        organUid: this.serviceAgreementAddDialog.serviceAgreementAddForm.organUid,
        organName: this.serviceAgreementAddDialog.serviceAgreementAddForm.organName,
        organCreditCode: this.serviceAgreementAddDialog.serviceAgreementAddForm.organCreditCode,
        contractDate: this.serviceAgreementAddDialog.serviceAgreementAddForm.contractDate,
        contractLimitDate: this.serviceAgreementAddDialog.serviceAgreementAddForm.contractLimitDate,
        qualificationList: this.serviceAgreementAddDialog.serviceAgreementAddForm.certificationFileList,
        agreementList: this.serviceAgreementAddDialog.serviceAgreementAddForm.agreementFileList,
      }
      this.$emit('loading', true)
      this.$system.postJson(
        this.api + '/oh/health/surveillance/addOrModifyPeServiceContract-1',
        data,
        true,
        true,
        data => {
          this.$emit('loading', false)
          if (data.type === '00') {
            this.$system.notify('成功', data.mess, 'success')
            this.$refs.serviceAgreementAddDialogRef.show(false)
            this.queryserviceAgreementList(1)
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
  },
}
</script>

<style lang="less" scoped>
.service-agreement-management {
  /*服务协议添加弹框样式*/
  /deep/ .el-dialog {
    width: 612px !important;
    min-width: 612px !important;
  }
  .service-agreement-add-dialog {
    /deep/ .organ-select .zwx-input {
      width: 450px !important;
    }
  }
}
</style>

<style lang="less"></style>
