<template>
  <div class="textBook_manage" style="padding-bottom: 20px;">
    <!-- 条件搜索部分 -->
    <el-form class="zwx-form" key="msSearchForm" ref="msSearchForm" :model="msSearchForm" label-position="right" @submit.native.prevent>
      <div class="condition-row">
        <el-form-item label="教材名称：" label-width="70px">
          <el-input class="zwx-input" style="width:220px !important" v-model.trim="msSearchForm.fileName" placeholder="" clearable />
        </el-form-item>
        <el-form-item label="分类：" label-width="50px">
          <el-checkbox-group class="zwx-checkbox-group" v-model="msSearchForm.categoryCodeArr">
            <el-checkbox class="zwx-checkbox" :label="1">教材</el-checkbox>
            <el-checkbox class="zwx-checkbox" :label="2">相关资料</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item label="专家：" label-width="50px">
          <el-input class="zwx-input" style="width:220px !important" v-model.trim="msSearchForm.expertName" placeholder="" clearable />
        </el-form-item>
        <el-form-item label="文件类别：" label-width="70px">
          <el-select class="zwx-select " popper-class="zwx-select-popper" v-model="msSearchForm.fileTypeArr" multiple collapse-tags placeholder="请选择" clearable>
            <el-option v-for="item in options" :key="item.label" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <div style="clear: both" />
      </div>
    </el-form>
    <div class="independent-button-row">
      <el-button class="zwx-button zwx-button-icontext-28" type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>

      <el-button class="zwx-button zwx-button-icontext-28" icon="el-icon-plus" @click="add">添加</el-button>
    </div>
    <!-- 列表展示部分 -->
    <el-table class="zwx-table" row-key="rid" :data="dataList" border stripe tooltip-effect="light">
      <el-table-column prop="categoryCode" label="分类" width="100" header-align="center" align="center">
        <template slot-scope="scope">
          {{ scope.row.categoryCode == 1 ? '教材' : '相关资料' }}
        </template>
      </el-table-column>
      <el-table-column prop="fileName" label="教材名称" width="300" header-align="center" align="left" />
      <el-table-column prop="expertName" label="专家" width="220" header-align="center" align="center" />
      <el-table-column prop="classHour" label="学时" width="70" header-align="center" align="center">
        <template slot-scope="scope">
          {{ scope.row.classHour && parseFloat(scope.row.classHour) }}
        </template>
      </el-table-column>
      <el-table-column prop="fileType" label="文件类别" width="110" header-align="center" align="center">
        <template slot-scope="scope">
          <i v-if="scope.row.fileType == 1" class="colorful chis-icon-colorful-pdf"></i>
          <i v-if="scope.row.fileType == 2" class="colorful chis-icon-colorful-mp4"></i>
          <i v-if="scope.row.fileType == 3" class="colorful chis-icon-colorful-word"></i>
          <i v-if="scope.row.fileType == 4" class="colorful chis-icon-colorful-excel"></i>
          <i v-if="scope.row.fileType == 5" class="colorful chis-icon-colorful-ppt"></i>
          <span v-if="$zwxBase.verifyIsBlank(scope.row.fileType)">--</span>
        </template>
      </el-table-column>
      <el-table-column prop="ifEnable" label="状态" width="90" header-align="center" align="center">
        <template slot-scope="scope">
          <div class="zwx-circle-text zwx-circle-normal" v-if="scope.row.ifEnable">启用</div>
          <div class="zwx-circle-text zwx-circle-finish" v-else>停用</div>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" :min-width="110" header-align="left" align="left">
        <template slot-scope="scope">
          <el-button class="zwx-button zwx-button-text-26" type="text" icon="el-icon-arrow-right" @click="edit(scope.row)">
            编辑
          </el-button>
          <el-button class="zwx-button zwx-button-text-26" type="danger" v-if="scope.row.ifEnable == false" @click="$system.msgbox(scope.row, '提示', '确定要删除吗？', '确定', '取消', deleteExamination)">删除</el-button>
          <el-button class="zwx-button zwx-button-text-26" :type="scope.row.ifEnable ? 'danger' : 'text'" @click="$system.msgbox(scope.row, '提示', scope.row.ifEnable ? '确定要停用吗？' : '确定要启用吗？', '确定', '取消', changeExaminationState)">
            {{ scope.row.ifEnable ? '停用' : '启用' }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <base-pagination class="normal-pagination" :parentPage="currentPage" :pageSize="pageSize" :total="total" @currentChange="search" />
  </div>
</template>

<script>
export default {
  name: 'MaterialManage',
  components: {},
  data() {
    return {
      api: this.$store.state.api,
      users: this.$store.state.users,
      //查询对象
      msSearchForm: {
        fileName: null,
        expertName: null,
        fileTypeArr: [],
        categoryCodeArr: [1, 2],
      },
      options: [
        {
          value: '1',
          label: 'pdf',
        },
        {
          value: '2',
          label: '视频',
        },
        {
          value: '3',
          label: 'word',
        },
        {
          value: '4',
          label: 'excel',
        },
        {
          value: '5',
          label: 'ppt',
        },
      ],
      //下方数据表格集合
      currentPage: 1,
      pageSize: 18,
      total: 0,
      dataList: [],
    }
  },
  mounted() {
    this.search(1)
  },
  activated() {
    if (this.$route.params.fresh) this.search(1)
  },
  methods: {
    add() {
      this.$router.push({ name: 'MaterialManageEdit', params: { type: 'add' } })
    },
    edit(row) {
      this.$router.push({ name: 'MaterialManageEdit', params: { row: row, type: 'edit' } })
    },
    //查询
    search(currentPage) {
      this.$emit('loading', true)
      this.currentPage = currentPage
      var ifEnable = null
      if (this.$zwxBase.verifyIsNotBlank(this.msSearchForm.ifEnableList) && this.msSearchForm.ifEnableList.length != 2) {
        ifEnable = this.msSearchForm.ifEnableList[0]
      }
      let data = {
        fileName: this.msSearchForm.fileName,
        expertName: this.msSearchForm.expertName,
        categoryCodeArr: this.msSearchForm.categoryCodeArr,
        fileTypeArr: this.msSearchForm.fileTypeArr,
        currentPage: this.currentPage,
      }
      this.$system.postJson(
        this.api + '/training/platform/getTeachingMaterialList-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.dataList = data.teachingMaterialList
            this.total = data.teachingMaterialCount
            this.pageSize = data.pageSize
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
          }
          this.$emit('loading', false)
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    //单体删除
    deleteExamination(row) {
      this.$emit('loading', true)
      let data = { uuid: row.uuid }
      this.$system.post(
        this.api + '/training/platform/deleteTeachingMaterial-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.search(1)
            this.$system.notify('成功', data.mess, 'success')
            this.currentPage = 1
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
            this.$emit('loading', false)
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
    //启用/停用
    changeExaminationState(row) {
      this.$emit('loading', true)
      let data = {
        uuid: row.uuid,
        ifEnable: !row.ifEnable,
      }
      this.$system.postJson(
        this.api + '/training/platform/enableOrDisableTeachingMaterial-1',
        data,
        true,
        true,
        data => {
          if (data.type === '00') {
            this.search(1)
            this.$system.notify('成功', data.mess, 'success')
            this.currentPage = 1
          } else if (data.type === '99') {
            this.$router.push({ name: 'ErrorPage', params: { mess: data.mess } })
          } else {
            this.$system.notify('错误', data.mess, 'error')
            this.$emit('loading', false)
          }
        },
        () => {
          this.$emit('loading', false)
          this.$system.notify('错误', '网络连接失败', 'error')
        }
      )
    },
  },
}
</script>

<style lang="less" scoped></style>

<style lang="less"></style>
